<template>
	<div class="card-shadow">
		<div class="pb-2">
			<v-container fluid class="py-0 px-0">
				<v-card flat class="custom-grey-border remove-border-radius">
					<v-card-title class="headline grey lighten-4 py-2">
						<h3 class="heading-h3" style="margin: 3.5px 0px">Permissions</h3>
					</v-card-title>
					<v-card-text class="p-6">
						<div class="v-data-table bt-table permission-height theme--light">
							<div class="v-data-table__wrapper">
								<table width="100%">
									<thead>
										<tr>
											<th width="200"></th>
											<th width="100">Full Access</th>
											<th width="100">View</th>
											<th width="100">Create</th>
											<th width="100">Edit</th>
											<th width="100">Delete</th>
											<th width="100">Approve</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(row, index) in permissionDataArray" :key="index">
											<td class="pt-3">
												<span class="fw-500 mb-0 pl-6">{{ moduleNameFormatted(index) }}</span>
											</td>
											<template v-for="(module, ind) in row">
												<td
													:key="index + ind"
													width="100"
													class="custom-border-top custom-border-right pl-4 pt-3"
												>
													<v-checkbox
														v-if="module.name"
														multiple
														:readonly="readonly"
														hide-details
														v-model="formPermissions"
														class="ma-0 pa-0"
														v-on:change="changeFullAccess(module, index)"
														:value="module.id"
													></v-checkbox>
												</td>
											</template>
											<td
												v-if="row.length != 6"
												:colspan="6 - row.length"
												class="custom-border-top pl-4 py-1"
											></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</v-card-text>
				</v-card>
			</v-container>
		</div>
	</div>
</template>
<script>
import { upperFirst, lowerCase } from "lodash";
import { SET_ERROR } from "@/core/services/store/common.module";
import { forEach, includes, findIndex, toSafeInteger, cloneDeep } from "lodash";

export default {
	name: "permission",
	title: "Permission",
	props: {
		permissionarray: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		updatePermissionsList: {
			type: Array,
			default: new Array(),
		},
		readonly: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			formPermissions: [],
			permissionDataArray: [],
			permissionHeader: { "full-access": 0, view: 1, create: 2, edit: 3, delete: 4, approve: 5 },
		};
	},
	watch: {
		updatePermissionsList: {
			deep: true,
			immediate: true,
			handler(param) {
				this.updatePermissions(param);
			},
		},
		permissionarray: {
			deep: true,
			immediate: true,
			handler(param) {
				this.makePermissions(param);
			},
		},
	},
	methods: {
		isShowCheckBox(module) {
			switch (module.name.toLowerCase()) {
				case "full access":
					return true;
				case "view":
					return true;
				case "create":
					return true;
				case "edit":
					return true;
				case "delete":
					return true;
				case "approve":
					return true;
				default:
					return false;
			}
		},
		makePermissions(params) {
			let headerData = cloneDeep(this.permissionHeader);
			let paramsData = cloneDeep(params);
			forEach(params, (row, index) => {
				let blankArr = [
					{ id: 0, name: "full-access" },
					{ id: null, name: "" },
					{ id: null, name: "" },
					{ id: null, name: "" },
					{ id: null, name: "" },
					{ id: null, name: "" },
				];
				forEach(row, (element) => {
					blankArr[headerData[element.name]] = element;
				});
				paramsData[index] = blankArr;
			});

			this.permissionDataArray = paramsData;
		},
		moduleNameFormatted(index) {
			if (index == "checkout") {
				index = "issue";
			}
			if (index == "checkin") {
				index = "receive";
			}
			if (index == "order") {
				index = "record";
			}
			return upperFirst(lowerCase(index));
		},
		changeFullAccess(param, index) {
			const _this = this;
			try {
				let permissionArray = cloneDeep(_this.permissionarray);
				if (param.name == "full-access") {
					let append = true;
					if (includes(_this.formPermissions, param.id) === false) {
						append = false;
					}

					for (let i = 0; i < permissionArray[index].length; i++) {
						if (append) {
							if (includes(_this.formPermissions, permissionArray[index][i].id) === false) {
								_this.formPermissions.push(permissionArray[index][i].id);
							}
						} else {
							let removeIndex = findIndex(_this.formPermissions, function (row) {
								return row == permissionArray[index][i].id;
							});
							if (removeIndex >= 0) {
								_this.formPermissions.splice(removeIndex, 1);
							}
						}
					}
				} else {
					if (includes(_this.formPermissions, param.id) === false) {
						for (let i = 0; i < permissionArray[index].length; i++) {
							if (permissionArray[index][i].name == "full-access") {
								let removeIndex = findIndex(_this.formPermissions, function (row) {
									return row == permissionArray[index][i].id;
								});
								if (removeIndex >= 0) {
									_this.formPermissions.splice(removeIndex, 1);
								}
							}
						}
					} else {
						let currentIndexValues = [];
						for (let i = 0; i < permissionArray[index].length; i++) {
							if (includes(_this.formPermissions, permissionArray[index][i].id)) {
								currentIndexValues.push(permissionArray[index][i].id);
							}
						}
						if (permissionArray[index].length === currentIndexValues.length + 1) {
							for (let i = 0; i < permissionArray[index].length; i++) {
								if (includes(_this.formPermissions, permissionArray[index][i].id) === false) {
									_this.formPermissions.push(permissionArray[index][i].id);
								}
							}
						}
					}
				}
				_this.$emit("updatePermissionData", _this.formPermissions);
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			}
		},
		checkAllPermissions() {
			let permissionArray = cloneDeep(_this.permissionarray);
			const _this = this;
			let result = [];
			for (let index in permissionArray) {
				for (let i = 0; i < permissionArray[index].length; i++) {
					if (includes(result, permissionArray[index][i].id) === false) {
						result.push(toSafeInteger(permissionArray[index][i].id));
					}
				}
			}
			_this.formPermissions = cloneDeep(result);
		},
		updatePermissions(permissionData) {
			const _this = this;
			let result = [];
			let permissionArray = cloneDeep(_this.permissionarray);
			for (let index in permissionArray) {
				for (let i = 0; i < permissionArray[index].length; i++) {
					if (includes(permissionData, permissionArray[index][i].id)) {
						result.push(toSafeInteger(permissionArray[index][i].id));
					}
				}
			}
			_this.formPermissions = cloneDeep(result);
		},
	},
};
</script>
